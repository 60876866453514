import { useTranslation } from "hooks/useTranslation";
import * as AUDIENCES from "constants/audiences";
import { FormattedMessage, useIntl } from "react-intl";
import Attachments from "./Attachments";

const PLACEHOLDER_ADAPTED_MEASURES_FACTOR = "{adapted_measures_factor}";
const Instructions = (props) => {
  const { assesment, assesmentTest } = props;
  
  const translatedIntro = useTranslation("message_intro", assesmentTest.test);
  // Mind the space before the placeholder (see note at the end of the file).
  const regex = new RegExp("\\s*" + PLACEHOLDER_ADAPTED_MEASURES_FACTOR, "g");
  const interpolatedIntro = translatedIntro.replace(regex, getAdaptedMeasuresTextByAudience(assesment, assesmentTest));

  return (
    <div>
      <h3>
        <FormattedMessage id="user-test.title.instructions" />
      </h3>
      <div
        id="intro-instructions"
        dangerouslySetInnerHTML={{
          __html: interpolatedIntro,
        }}
      />

      <Attachments attachments={assesmentTest.test.attachments} />
    </div>
  );
}

const getAdaptedMeasuresTextByAudience = (assesment, assesmentTest) => {
  if(!assesment.candidate_adapted_measures_factor) return "";
  if(!assesmentTest.adapted_measures_allowed) return "";

  const intl = useIntl();
  const factor = Math.round(parseFloat(assesment.candidate_adapted_measures_factor));

  let translationId = null;
  switch(assesment.evaluation_session.audience_type_id){
    case AUDIENCES.ACADEMIC_PRIMARY:
      translationId = "home.academic-10.intro.adapted_measures_increase";
    case AUDIENCES.ACADEMIC_COLLEGE:
      translationId = "home.college.intro.adapted_measures_increase";
    default:
      translationId = "home.professional.intro.adapted_measures_increase";
  }


  return " " + intl.formatMessage({ id: translationId},{factor: factor }); // Mind the space added before the string
}

export default Instructions;


/**
 * Note about the space before the placeholder:
 * The text contains something like "You will have exactly 10 minutes {adaped_measures_factor} to answer (...)".
 * To avoid double spaces when adapted_measures are not allowed or non-existent, the regex will match the space before the placeholder
 * so it could be replaced with an empty string.
 */